import Button from './Button';
import InputText from './Input/InputText';
import { BoardSearch } from '@/components/Css/Board';
import React, { FC, FormHTMLAttributes, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';

interface BoardSearchBarProps extends FormHTMLAttributes<HTMLFormElement> {
  initialKeyword: string;
  onSearch: (value: string) => void;
}

interface SearchFormData {
  keyword: string;
}

const BoardSearchBar = styled<FC<BoardSearchBarProps>>(
  ({ initialKeyword, onSearch, ...props }) => {
    const { register, handleSubmit, setValue } = useForm<SearchFormData>();

    const onSubmit: SubmitHandler<SearchFormData> = ({ keyword }) => {
      onSearch(keyword);
      return false;
    };

    useEffect(() => {
      // 검색어가 비워질때 함께 동기화 (검색중에 초기페이지로 갈 경우)
      if (!initialKeyword) {
        setValue(`keyword`, ``);
      }
    }, [initialKeyword, setValue]);

    return (
      <form {...props} onSubmit={handleSubmit(onSubmit)}>
        <BoardSearch>
          <div className="label-wrap">
            <InputText
              ref={register}
              name="keyword"
              placeholder="검색어를 입력하세요"
              defaultValue={initialKeyword}
            />
            <button type="submit">
              <span className="a11y">검색</span>
            </button>
          </div>

          <div className="col-btn">
            <Button
              color="white-grey"
              outline
              onClick={() => {
                setValue(`keyword`, ``);
                onSearch(``);
              }}
              css={`
                font-size: 16px;
              `}
            >
              전체보기
            </Button>
          </div>
        </BoardSearch>
      </form>
    );
  },
)``;

export default BoardSearchBar;
