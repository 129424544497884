import { BoardCommonControllerService } from '@/__generated__/CommonApi';
import IconFileAttach from '@/assets/img/ico_file_attach@2x.png';
import { breakpoint } from '@/helpers/BreakpointHelper';
import { usePopupStore } from '@/stores/PopupStore';
import dayjs from 'dayjs';
import { Link } from 'gatsby';
import React, { FC } from 'react';
import styled from 'styled-components';

interface NoticeItemProps {
  notice: {
    topYn?: string;
    attGrpNo?: string;
    boardIndexNumber?: string;
    subject?: string;
    firstRegisterDate?: string;
  };
  basePath?: string;
  queryString?: string;
}

const ItemContainer = styled.li<{ notice?: boolean }>`
  display: flex;
  padding: 32px 0;
  border-bottom: 1px solid #e5e6e8;
  ${({ notice }) => {
    if (notice) {
      return `
        color: #1cabe2;
      `;
    }
    return ``;
  }}
  ${breakpoint(640)} {
    padding: 16px 0;
  }
`;
const ItemContent = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  ${breakpoint(640)} {
    display: block;
  }
`;
const ItemTitle = styled.p`
  font-size: 20px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 80%;
  ${breakpoint(640)} {
    width: 100%;
    font-size: 16px;
    max-height: 52px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
  }
  .link-item {
    &:hover {
      text-decoration: underline;
    }
  }
`;
const ItemNote = styled.p`
  font-size: 16px;
  ${breakpoint(640)} {
    font-size: 14px;
    display: block;
    margin-top: 8px;
  }
`;
const NewText = styled.span`
  font-size: 14px;
  color: #1cabe2;
  font-weight: normal;
  display: inline-block;
  margin-left: 8px;
`;
const NoticeText = styled.span`
  display: inline-block;
  font-size: 14px;
  color: #fff;
  background: #1cabe2;
  height: 36px;
  line-height: 36px;
  padding: 0 16px;
  text-align: center;
  font-weight: normal;
  margin-right: 12px;
  & + a {
    color: #1cabe2;
  }
  ${breakpoint(640)} {
    padding: 0 12px;
    height: 24px;
    line-height: 24px;
    font-size: 12px;
  }
`;

const H2Title = styled.h2`
  display: inline;
  font-size: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${breakpoint(640)} {
    font-size: 16px;
  }
`;

/**
 * 게시글 목록 아이템
 */
const NoticeItem: FC<NoticeItemProps> = ({ basePath, queryString, notice }) => {
  const popupStore = usePopupStore();

  const date = new Date();
  const year = date.getFullYear();
  const month = `0${1 + date.getMonth()}`.slice(-2);
  const day = `0${date.getDate()}`.slice(-2);

  const today = year + month + day;

  const updateViewCnt = async () => {
    const {
      resultCode,
      resultMessage,
    } = await BoardCommonControllerService.viewCntUsingPost({
      boardIndexNumber: notice.boardIndexNumber || ``,
    });

    if (resultCode !== `success`) {
      if (resultMessage !== undefined) {
        popupStore.show(resultMessage);
      } else {
        popupStore.show(
          `공지사항: ${notice.boardIndexNumber} 조회수 증가를 실패했습니다.`,
        );
      }
    }
  };

  return (
    <>
      <ItemContainer>
        <ItemContent>
          <ItemTitle>
            {notice.topYn === `Y` && <NoticeText>중요</NoticeText>}
            <Link
              className="link-item"
              to={`${basePath}/${notice.boardIndexNumber}${queryString}`}
              // onClick={updateViewCnt}
            >
              <H2Title>{notice.subject}</H2Title>
            </Link>
            {notice.attGrpNo && (
              <img src={IconFileAttach} alt="첨부파일" width="22" />
            )}

            {dayjs(notice.firstRegisterDate, `YYYY.MM.DD`)
              .format(`YYYYMMDD`)
              .substr(0, 6) === today.substr(0, 6) ? (
              <NewText>new</NewText>
            ) : null}
          </ItemTitle>
          <ItemNote>
            {dayjs(notice.firstRegisterDate, `YYYY.MM.DD`).format(`YYYY.MM.DD`)}
          </ItemNote>
        </ItemContent>
      </ItemContainer>
    </>
  );
};

export default NoticeItem;
